import { useQuery } from '@tanstack/react-query'
import { AxiosError } from 'axios'
import { useRouter } from 'next/router'
import { useEffect } from 'react'

import { api } from '~/clients/api'
import { type User, UserSchema } from '~/schema/user'

export const accessTokenCookie = 'accessToken'
export const errorTokenNotFound = 'Access token not found'

import Cookies from 'js-cookie'

export function getAccessTokenCookie() {
  const token = Cookies.get(accessTokenCookie)
  if (!token) throw new Error(errorTokenNotFound)
  return token
}

export function getAccessToken() {
  return `Bearer ${getAccessTokenCookie()}`
}

export function setAccessToken(token: string) {
  Cookies.set(accessTokenCookie, token)

  // FIXME: still needed for shop manager; should be removed after sunsetting that app.
  // The same applies to `removeAccessToken`
  typeof window !== 'undefined' &&
    sessionStorage.setItem('authorization', `Bearer ${token}`)
}

export function removeAccessToken() {
  Cookies.remove(accessTokenCookie)

  // FIXME:
  typeof window !== 'undefined' && sessionStorage.removeItem('authorization')
}

async function fetchUserData() {
  const response = await api.authentication.refresh()

  const user = response.data

  try {
    return UserSchema.parse(user)
  } catch (error) {
    console.error(error)
    return user as User
  }
}

export function useUser() {
  const router = useRouter()
  const query = useQuery({
    queryKey: ['user'],
    queryFn: () => fetchUserData(),
    retry: false,
    // 5 minutes
    staleTime: 1000 * 60 * 5,
  })
  useEffect(() => {
    if (query.isError) {
      const err = query.error
      const isClientServerError =
        err instanceof AxiosError &&
        err.response?.status &&
        err.response.status >= 400
      const isMissingAccessToken = err.message === errorTokenNotFound

      if (isClientServerError || isMissingAccessToken) {
        window.sessionStorage.removeItem('ssoCode')
        removeAccessToken()
        router.push('/')
      }
    }
  }, [query.isError, query.error, router])

  return query
}
